<template>
  <!-- 隐私政策 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <le-card title="隐私政策">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('4d77c05b2d384f83843cca2a')">删除</lbButton>
          <lbButton icon="xinzeng" @click="batchAdd" v-if="isShowBtn('4d77c05b2d384f82143cca2a')">新增</lbButton>
        </div>
      </template>
      <!-- 表格 -->
      <!-- 使用插件生产表格 -->
      <!-- <avue-crud :data="tableData" :option="option" @selection-change="selectionChange">
        <template slot-scope="{row}" slot="phone_client">
          {{ getWayName('GroupPhoneClient', row.phone_client) }}
        </template>
        <template slot-scope="{row}" slot="create_time">
          {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template slot-scope="{row}" slot="menu">
          <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditDialog(row)"></lbButton>
        </template>
      </avue-crud> -->
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :fit="true" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
      <el-table-column label="手机端">
        <template slot-scope="scope">
          {{ getWayName('GroupPhoneClient', scope.row.phone_client) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditDialog(scope.row)" v-if="isShowBtn('4d77c05b2d384f82843cca2a')"></lbButton>
        </template>
      </el-table-column>
    </el-table>
    </le-card>
    <lebo-dialog append-to-body :title="addForm._id ? '编辑' : '新增'" :isShow="isAddAndEditDialog" width="70%"
      @open="openDialogAfter" @close="closeDialog" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px" class="demo-ruleForm">
        <el-form-item label="手机端：" prop="phone_client">
          <el-select popper-class="my-select" v-model="addForm.phone_client" filterable default-first-option
            placeholder="请选择手机端">
            <el-option v-for="item in phoneCilentList" :key="item" :label="getWayName('GroupPhoneClient', item)"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容：" prop="Content">
          <div id="content"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back"  @click="isAddAndEditDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()">保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>
<script>
import E from 'wangeditor'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getMaintenanceMain, getMaintenanceMainDetail, getPhoneCilentList, delMaintenanceMain, addMaintenanceMain, updateMaintenanceMain } from '@/api/maintenanceCenter'
export default {
  data () {
    return {
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      isAddAndEditDialog: false,
      addForm: {
        _id: '',
        Type: 4, // 类型  开发者 = 1, 小助手 = 2, 用户协议 = 3, 隐私政策 = 4, 咨询热线 = 5, 公司官网 = 6
        Content: '', // 内容
        phone_client: null // 手机端类型
      },
      addFormRules: {
        phone_client: [{ required: true, message: '请选择手机端', trigger: 'blur' }],
        Content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      // 富文本编辑器
      editor: null,
      phoneCilentList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '手机端',
            prop: 'phone_client',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      }
    }
  },
  created () {
    this.fnGetMaintenanceMain()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    async fnGetMaintenanceMain () {
      // 类型  开发者 = 1, 小助手 = 2, 用户协议 = 3, 隐私政策 = 4, 咨询热线 = 5, 公司官网 = 6
      // Type: 3,
      const res = await getMaintenanceMain({ type: 4 })
      // console.log(res);
      this.tableData = res && res.Code === 200 ? res.Data : []
    },
    // 当选中项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的用户协议, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = []
          this.multipleSelection.forEach(item => {
            console.log(item)
            arr.push(item.id)
          })
          console.log(arr, arr.join(','))
          this.fnDelMaintenanceMain({ id: arr.join(',') })
          // for (var i = 0; i < this.multipleSelection.length; i++) {
          //   arr.push(this.multipleSelection[i]._id);
          //   if (arr.length === this.multipleSelection.length) {
          //     obj._ids = arr;
          //     this.delItem(obj);
          //   }
          // }
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的停车场！')
      }
    },
    // 删除请求
    async fnDelMaintenanceMain (obj) {
      const res = await delMaintenanceMain(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetMaintenanceMain()
    },
    // 打开新增对话框
    batchAdd () {
      this.isAddAndEditDialog = true
      this.fnGetPhoneCilentList()
    },
    // 获取手机端下拉列表
    async fnGetPhoneCilentList (id) {
      var obj = { type: 4 }
      if (id) {
        obj._id = id
      }
      const res = await getPhoneCilentList(obj)
      this.phoneCilentList = res && res.Code === 200 ? res.Data : []
      // console.log(res);
    },
    // 打开编辑对话框
    openEditDialog (item) {
      this.isAddAndEditDialog = true
      this.fnGetPhoneCilentList(item.id)
      this.fngetMaintenanceMainDetail(item.id)
    },
    // 获取详情
    async fngetMaintenanceMainDetail (id) {
      const res = await getMaintenanceMainDetail({ id: id })
      console.log(res)
      if (res && res.Code === 200) {
        this.addForm = JSON.parse(JSON.stringify(res.Data))
        this.editor && this.editor.txt.html(this.addForm.Content)
      }
    },
    openDialogAfter () {
      var that = this
      this.$nextTick(() => {
        // console.log(document.getElementById('content'));
        that.editor = new E(document.getElementById('content'))
        // 配置菜单栏，删减菜单，调整顺序
        that.editor.config.menus = [
          'head',
          'bold',
          'fontSize',
          'fontName',
          'italic',
          'underline',
          'strikeThrough',
          'indent',
          'lineHeight',
          'foreColor',
          'backColor',
          'list',
          'justify',
          'table',
          'splitLine',
          'undo',
          'redo'
        ]
        that.editor.config.placeholder = '请输入内容...'
        // that.editor.config.focus = true
        // 配置 onchange 回调函数
        that.editor.config.onchange = function (newHtml) {
          // console.log("change 之后最新的 html", newHtml);
        }
        // 配置触发 onchange 的时间频率，默认为 200ms
        that.editor.config.onchangeTimeout = 1000 // 修改为 500ms
        // 配置全屏功能按钮是否展示
        that.editor.config.showFullScreen = false
        that.editor.config.height = 400
        that.editor.create()
        if (that.addForm._id) { // 编辑
          that.editor.txt.html(that.addForm.Content)
        }
      })
    },
    // 点击保存按钮
    submitAddForm () {
      this.addForm.Content = this.editor.txt.html()
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          if (this.addForm._id) {
            this.fnUpdateMaintenanceMain()
          } else {
            this.fnAddMaintenanceMain()
          }
        }
      })
    },
    // 更新用户协议
    async fnUpdateMaintenanceMain () {
      const res = await updateMaintenanceMain(this.addForm)
      //   console.log('更新用户协议----', res);
      if (res.Code === 200) {
        this.fnGetMaintenanceMain()
        this.isAddAndEditDialog = false
      }
    },
    // 新增用户协议
    async fnAddMaintenanceMain () {
      const res = await addMaintenanceMain({
        phone_client: this.addForm.phone_client,
        Type: this.addForm.Type,
        Content: this.addForm.Content
      })
      // console.log('新增用户协议----', res);
      if (res.Code === 200) {
        this.fnGetMaintenanceMain()
        this.isAddAndEditDialog = false
      }
    },
    closeDialog () {
      this.isAddAndEditDialog = false
      this.$refs.addFormRef.resetFields()
      this.addForm = {
        _id: '',
        Type: 4, // 类型  开发者 = 1, 小助手 = 2, 用户协议 = 3, 隐私政策 = 4, 咨询热线 = 5, 公司官网 = 6
        Content: '', // 内容
        phone_client: null // 手机端类型
      }
      this.editor.destroy()
      this.editor = null
      console.log(this.addForm)
    }
  }
}
</script>
<style scoped lang="less">
#content {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
}</style>
